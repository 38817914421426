import {extendTheme} from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    primary: 'blue',
  },
  fonts: {
    heading: `"Mononoki", cursive`,
    body: `"Anybody", cursive`,
  },
});

export default theme;
